<template>
  <form
    ref="root"
    class="inline-flex justify-center items-center min-w-[140px] min-h-[46px] relative z-0"
    @submit.prevent="form.handleSubmit"
  >
    <p
      :class="[
        'absolute left-1/2 bottom-full -translate-x-1/2 w-[320px] mb-3 text-center font-[670] text-xs text-[#707070] transition-opacity duration-300',
        progress < 0.8 ? 'opacity-0' : 'opacity-1',
      ]"
    >
      Get access to case studies and product specs
    </p>
    <div
      class="input"
      :class="{ 'input--active': active }"
      :style="{
        width: `${fromTo(106, 300, buttonRatio, 1)}px`,
        right: `-${fromTo(0, 8, 0, buttonRatio)}px`,
        opacity: `${fromTo(0, 1, buttonRatio * 0.5, buttonRatio)}`,
      }"
    >
      <JBorder
        class="w-full h-full rounded-[5px_6px_6px_5px]"
        :background-style="{
          background: 'transparent',
        }"
      >
        <div class="input__inner">
          <div
            class="max-w-full p-[16px] overflow-hidden"
            :style="{
              marginRight: `${fromTo(146, 106, 0, buttonRatio)}px`,
            }"
          >
            <input
              type="email"
              placeholder="your business email"
              class="w-full outline-none font-[570] bg-transparent text-base text-[#070707] placeholder:text-[#C5C5C5] placeholder:text-sm"
              name="email"
              required
              aria-required="true"
              aria-invalid="false"
              @input="form.handleInput"
            />
          </div>
        </div>
      </JBorder>
    </div>
    <button
      class="origin-right"
      :style="{
        fontSize: `${fromTo(16, 12, 0, buttonRatio)}px`,
        height: `${fromTo(46, 32, 0, buttonRatio)}px`,
        width: `${fromTo(140, 90, 0, buttonRatio)}px`,
        transform: `translateX(${fromTo(0, 98, buttonRatio, 1)}px)`,
        borderRadius: `${fromTo(5, 3, 0, buttonRatio)}px`,
      }"
      :disabled="form.processing.value"
      :type="active ? 'submit' : 'button'"
      @click="onClick"
    >
      Tell me more
    </button>

    <p
      v-if="form.error.value"
      class="absolute left-1/2 w-[320px] top-full mt-2 font-[570] text-xs text-[#FF0080] text-center -translate-x-1/2"
    >
      Something went wrong, please try again.
    </p>
    <p
      v-else-if="form.success.value"
      class="absolute left-1/2 w-[320px] top-full mt-2 font-[570] text-xs text-[#7928CA] text-center -translate-x-1/2"
    >
      All set. We'll get in touch with you.
    </p>
  </form>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import JBorder from "./JBorder.vue";
import { useContactForm } from "../utilities/contactForm";

const root = ref(null as unknown as HTMLFormElement);
const form = useContactForm({
  form: root,
  formId: "299be045f4004ca",
});
const active = ref(false);

const duration = 400;
const progress = ref(0);

const buttonRatio = 0.4;

function fromTo(min: number, max: number, start = 0, end = 1) {
  return (
    min +
    (max - min) *
      Math.max(0, Math.min((progress.value - start) / (end - start), 1))
  );
}

const start = ref<number | null>(null);
const playing = ref(false);

function step(timestamp: number) {
  if (start.value == null) {
    start.value = timestamp;
  }

  const elapsed = timestamp - start.value;
  const ratio = Math.min(elapsed / duration, 1);
  progress.value = active.value ? ratio : 1 - ratio;

  if (elapsed < duration) {
    window.requestAnimationFrame(step);
  } else {
    start.value = null;
    playing.value = false;
  }
}

watch(active, () => {
  if (!playing.value) {
    playing.value = true;
    window.requestAnimationFrame(step);
  }

  if (active.value === false) {
    form.resetState();
  }
});

onMounted(() => {
  onClickOutside(root, () => {
    active.value = false;
  });
});

function onClick(event: MouseEvent) {
  if (!active.value) {
    active.value = true;
    event.preventDefault();
  }
}
</script>

<style scoped>
.input {
  @apply absolute inset-y-0 left-1/2 rounded-[5px_6px_6px_5px] -translate-x-1/2;
  @apply overflow-hidden;
}

.input__inner {
  @apply relative flex items-center w-full h-full rounded-[5px_6px_6px_5px] bg-white border border-transparent bg-clip-padding z-10;
  @apply text-[#707070] text-[12px] tracking-[-1%] whitespace-nowrap;
}

button {
  @apply relative z-10 flex items-center justify-center w-[140px] h-[46px] bg-white border border-[#C5C5C5] active:top-[1px] font-[570] text-[#3726F4] tracking-[-0.01em] hover:border-[#3726F4] disabled:opacity-50 transition-colors duration-200 ease-in-out;
}
</style>
